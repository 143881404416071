<template>
  <div>
    <!-- CUSTOMER TYPE -->
    <b-form-group :label="$t('register.you_are') + ' *'" label-for="register-customer-you-are">
      <b-overlay :show="typologiesLoading">
        <validation-provider #default="{ errors }" name="Type" rules="required" class="validation-required">
          <b-form-radio-group id="organizationTypology" v-model="customerType">
            <b-form-radio v-for="typology in typologies" :key="typology.id" :value="typology.id">
              {{ typology.name | trans }}
            </b-form-radio>
          </b-form-radio-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-overlay>
    </b-form-group>
    <!-- COMPANY NAME -->
    <app-input
      id="register-customer-company-name"
      v-model="companyName"
      :label="$t('user.company_name')"
      required
    />
    <!-- TVA -->
    <app-input
      id="register-customer-tva"
      v-model="tva"
      :label="$t('register.tva')"
      rules="required"
    />
    <!-- ADDRESS -->
    <app-input
      id="register-customer-address"
      v-model="address"
      :label="$t('address.name')"
      rules="required"
      required
    />

    <!-- MORE INFORMATIONS -->
    <app-input
      id="register-customer-address"
      v-model="complementaryAddress"
      :label="$t('address.more_informations')"
    />
    <!-- COUNTRY -->
    <enum-select
      id="register-customer-country"
      v-model="country"
      :label="$t('common.country')"
      text="text"
      enum-class="CountryCode"
      required
    />
    <b-row>
      <!-- POSTAL CODE -->
      <b-col cols="6">
        <app-input
          id="register-customer-postal-code"
          v-model="postalCode"
          :label="$t('address.postal_code')"
          required
        />
      </b-col>
      <!-- CITY -->
      <b-col cols="6">
        <app-input
          id="register-customer-city"
          v-model="city"
          :label="$t('address.city')"
          required
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  fetchOrganizationConfigurationTypologiesRequest,
} from '@/request/globalApi/requests/organizationConfigurationRequests'

export default {
  name: 'CustomerMainInformation',
  data() {
    return {
      customerType: null,
      typologiesLoading: true,
      typologies: [],
      companyName: null,
      tva: null,
      address: null,
      complementaryAddress: null,
      country: null,
      postalCode: null,
      city: null,
    }
  },

  mounted() {
    this.APIFetchTypologies()
  },

  methods: {
    ...mapActions('registration', [
      'setUserClientTypologyId',
      'setUserLegalName',
      'setUserIntracommunityVatNumber',
      'setUserAddress',
      'setUserComplementaryAddress',
      'setUserCountryCode',
      'setUserPostalCode',
      'setUserCity',
    ]),
    APIFetchTypologies() {
      fetchOrganizationConfigurationTypologiesRequest({ getAllItems: true, userType: 'buyer', optionable: false }).then(response => {
        const { typologies } = response.data
        this.typologies = typologies
        this.typologiesLoading = false
      })
    },
    store() {
      this.setUserClientTypologyId(this.customerType)
      this.setUserLegalName(this.companyName)
      this.setUserIntracommunityVatNumber(this.tva)
      this.setUserAddress(this.address)
      this.setUserComplementaryAddress(this.complementaryAddress)
      this.setUserCountryCode(this.country)
      this.setUserPostalCode(this.postalCode)
      this.setUserCity(this.city)
    },
  },
}
</script>
